import React from 'react'
import WrLink from '../../commons/wr-link'
import { StyledTextFlag } from './styles'

export default function TextFlag({ href = '', backgroundColor, textColor, alignFlag, text }) {
    return (
        <StyledTextFlag
            as={href ? WrLink : ''}
            href={href}
            $align={alignFlag}
            $backgroundColor={backgroundColor}
            $textColor={textColor}
            dangerouslySetInnerHTML={{ __html: text }}
        />
    )
}
