import styled, { css } from 'styled-components';
import { theme } from '@theme';

export const StyledTextFlag = styled.div`
    display: block;
    padding: 8px 12px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    font-size: 1.4rem;
    background-color: ${props => props.$backgroundColor || '#C3F4ED'};
    color: ${props => props.$textColor || 'black'};
    text-align: center;
    min-width: 200px;
    width: 100%;
    max-width: 350px;
    margin: 0 auto 25px auto;
    text-decoration: none;

    ${theme.mediaQuerys.smUp} {
        ${props => props.$align === 'left' || !props.$align ? css`
            margin-left: 0;
        `: props.$align === 'right' ? css`
            margin-right: 0;
        `: null}
        width: 100%;
    }

    ${theme.mediaQuerys.mdUp} {
        padding: 5px 20px;
        font-size: 1.6rem;
        max-width: 400px;
    }
`